import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from 'src/environments/environment';
import { setupCache } from 'axios-cache-interceptor';
import { bypassedApisWithoutEndpoint } from 'src/app/utility/static-data/bypassed-apis';
import { getTokenFromStorage } from 'src/app/utility/common-logic';

const _axios = setupCache(axios, {
  cacheTakeover: false,
  methods: ['get'],
  ttl: 1000 * 60 * 10,
});

const bypassedApis = bypassedApisWithoutEndpoint;
@Injectable({
  providedIn: 'root',
})
export class HttpService {
  token = '';
  httpOptions: any;
  defaultLanguage = environment.settings.defaultLanguage;
  debug = environment.settings.debug;

  constructor() {
    axios.defaults.baseURL = environment.url.app;
  }

  get lang() {
    const localLang = localStorage.getItem('lang');
    return !localLang ? this.defaultLanguage : localLang;
  }

  private _setHeaders(
    serviceName: string,
    ignoreJsonContentType = false,
    multipartFormData = false,
  ) {
    const headers: any = { 'Accept-Language': this.lang };
    if (!ignoreJsonContentType) {
      headers['Content-Type'] = 'application/json';
    }
    this.token = getTokenFromStorage();
    if (!!this.token && !bypassedApis.some((x) => serviceName.startsWith(x))) {
      headers['Authorization'] = `Token ${this.token}`;
    }
    if (multipartFormData) {
      headers['Content-Type'] = 'multipart/form-data';
    }
    // new HttpHeaders({'Content-Type': 'multipart/form-data'})
    this.httpOptions = { headers };
  }

  async get(
    serviceName: string,
    cacheData?: { cache?: boolean; initiateApiCall?: boolean },
    id?: string,
  ) {
    this._setHeaders(serviceName);
    const url = environment.url.app + serviceName;

    if (cacheData?.cache && !cacheData?.initiateApiCall) {
      const headers = { ...this.httpOptions, cache: true };
      if (id != null && id != '') {
        headers.id = id;
      }
      return _axios.get(url, headers);
    } else {
      return axios.get(url, { ...this.httpOptions, cache: false });
    }
  }

  async post(
    serviceName: string,
    data: any,
    ignoreJsonContentType = false,
    multipartFormData = false,
  ) {
    this._setHeaders(serviceName, ignoreJsonContentType, multipartFormData);
    const url = environment.url.app + serviceName;
    return await axios.post(url, data, this.httpOptions);
  }

  async put(serviceName: string, data: any, ignoreJsonContentType = false) {
    this._setHeaders(serviceName, ignoreJsonContentType);
    const url = environment.url.app + serviceName;
    return await axios.put(url, data, this.httpOptions);
  }

  async delete(serviceName: string, postMethod = true, data: any = null) {
    this._setHeaders(serviceName);
    const url = environment.url.app + serviceName;
    if (postMethod) {
      return await axios.post(url, null, this.httpOptions);
    } else {
      if (data) {
        return await axios.delete(url, { ...this.httpOptions, data: data });
      } else {
        return await axios.delete(url, this.httpOptions);
      }
    }
  }

  async patch(
    serviceName: string,
    patchData: any,
    ignoreJsonContentType = false,
    multipartFormData = false,
  ) {
    this._setHeaders(serviceName, ignoreJsonContentType, multipartFormData);
    const url = environment.url.app + serviceName;
    return await axios.patch(url, patchData, this.httpOptions);
  }

  clearStorage() {
    const keys = Object.keys((_axios.storage as any).data);
    keys.forEach((key) => {
      _axios.storage.remove(key);
    });
  }
}
