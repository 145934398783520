import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/api-services/api.service';
import { SharedService } from 'src/app/services/shared.service';
import {
  Appreciate,
  setAppreciate,
} from 'src/app/state/appreciate/appreciate.actions';
import { selectAuth } from 'src/app/store/auth/auth.selector';
import { TokenAuthState } from 'src/app/store/auth/auth.state';
import {
  formatNavArrayItems,
  isAppreciateEnabled,
  isBenefitReceiptEnabled,
  isCalendarEnabled,
  isEventsAnnouncementEnabled,
  isLeaderboardEnabled,
  isNominationEnabled,
  isPostPollEnabled,
  isRewardEnabled,
  isSalesReceiptEnabled,
} from 'src/app/utility/common-logic';
import { FeedType } from 'src/app/utility/enum';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bottom-menu',
  templateUrl: './bottom-menu.component.html',
  styleUrls: ['./bottom-menu.component.scss'],
})
export class BottomMenuComponent {
  modules = environment.settings.modules;
  showActive = environment.settings.showActiveDotInBottomMenu;
  cartDetails: any;
  cartCount: number;
  to: string = 'home';
  moreMenu = inject(NgbModal);
  moreButtonHidden: boolean = environment.settings.showMoreButtonInBottomMenu;
  feeds = environment.settings.feeds;
  flexiBenefitLink = '';
  code: string = '';
  isShowAnnouncementEvents = environment.settings.isShowAnnouncementEvents;
  showSettingsInMenu = environment.settings.showSettingsInMenu;
  navMenuItems = [];
  orderOfMenuItems = environment.settings.orderOfMenuItems;
  auth$: TokenAuthState;

  constructor(
    private router: Router,
    private sharedService: SharedService,
    private activatedRoute: ActivatedRoute,
    private appreciateStore: Store<{ appreciate: Appreciate }>,
    private translate: TranslateService,
    private api: ApiService,
    private store: Store,
  ) {}

  async ngOnInit() {
    this.activatedRoute.url.subscribe((url: UrlSegment[]) => {
      this.to = url.length > 0 ? url[0].path : this.router.url.split('/')[1];
    });
    if (this.modules.cart) {
      this.sharedService.cartCount.subscribe((count) => {
        this.cartCount = count;
      });
    }

    this.store.pipe(select(selectAuth)).subscribe((auth) => {
      this.auth$ = auth;
      let updatedNavMenuItems: string[] = ['home'];

      if (this.showSettingsInMenu) {
        updatedNavMenuItems.push('settings');
      }

      if (isRewardEnabled(this.auth$)) {
        updatedNavMenuItems.push('rewards');
      }

      if (this.modules.myWallet) {
        updatedNavMenuItems.push('point-history');
      }

      if (
        (isAppreciateEnabled(this.auth$) && isNominationEnabled(this.auth$)) ||
        isAppreciateEnabled(this.auth$)
      ) {
        updatedNavMenuItems.push('appreciate');
      } else if (isNominationEnabled(this.auth$)) {
        updatedNavMenuItems.push('nomination');
      }

      if (
        isAppreciateEnabled(this.auth$) &&
        updatedNavMenuItems.includes('nomination')
      ) {
        updatedNavMenuItems = updatedNavMenuItems.filter(
          (x) => x !== 'nomination',
        );
      }

      if (
        isAppreciateEnabled(this.auth$) ||
        isNominationEnabled(this.auth$) ||
        isPostPollEnabled(this.auth$)
      ) {
        updatedNavMenuItems.push('feeds');
      }

      if (isCalendarEnabled(this.auth$) && !this.isShowAnnouncementEvents) {
        updatedNavMenuItems.push('calendar');
      }

      if (!isCalendarEnabled(this.auth$) || this.isShowAnnouncementEvents) {
        updatedNavMenuItems = updatedNavMenuItems.filter(
          (x) => x !== 'calendar',
        );
      }

      if (
        isEventsAnnouncementEnabled(this.auth$) &&
        this.isShowAnnouncementEvents
      ) {
        updatedNavMenuItems.push('announcement', 'event');
      }

      if (
        isBenefitReceiptEnabled(this.auth$) &&
        !isSalesReceiptEnabled(this.auth$)
      ) {
        updatedNavMenuItems.push('receipts');
      }

      if (isSalesReceiptEnabled(this.auth$)) {
        updatedNavMenuItems.push('sales-receipts');
      }

      if (isLeaderboardEnabled(this.auth$)) {
        updatedNavMenuItems.push('leaderboard');
      }

      if (this.auth$?.organization_settings?.flexi_benefits_url) {
        updatedNavMenuItems.push('flexiBenefits');
      }

      this.navMenuItems = formatNavArrayItems(
        this.orderOfMenuItems,
        updatedNavMenuItems,
      );
    });
  }

  is(to: string) {
    return (
      (to === 'my-wallet' && to === this.router.url.split('/')[2]) ||
      (to === 'appreciate' && to === this.router.url.split('/')[1]) ||
      (to === 'receipts' && this.router.url.split('/')[1] === 'receipt-list') ||
      (to === 'sales-receipts' &&
        this.router.url.split('/')[1] === 'sales-receipt-list') ||
      (to === 'announcement' && to === this.router.url.split('/')[2]) ||
      (to === 'event' && to === this.router.url.split('/')[2]) ||
      this.to === to
    );
  }

  selectedClass(to: string) {
    return this.is(to) ? 'selected' : '';
  }

  fontClass(to: string) {
    return this.to === to ? 'font-selected' : 'font-unselected';
  }

  openModal(content) {
    this.moreMenu.open(content, {
      windowClass: 'modal-bottom-right',
      backdrop: true,
    });
  }

  get bottomMenus() {
    if (this.navMenuItems && this.navMenuItems.length <= 5) {
      return this.navMenuItems;
    } else if (this.navMenuItems && this.navMenuItems.length > 5) {
      return this.navMenuItems.slice(0, 4);
    } else {
      return [];
    }
  }

  get moreMenus() {
    return this.navMenuItems.slice(4);
  }

  get link() {
    if (this.code === 'appreciate') {
      return ['/appreciate'];
    }
    if (this.code === 'nomination') {
      return ['/appreciate'];
    }

    if (this.code === 'feeds') {
      if (
        !isAppreciateEnabled(this.auth$) &&
        !isNominationEnabled(this.auth$)
      ) {
        return ['/' + this.feeds.path, 'post-n-polls'];
      }
      return ['/' + this.feeds.path, 'appreciation'];
    }

    if (this.code === 'rewards') {
      return ['/rewards'];
    }
    if (this.code === 'my-wallet') {
      return ['/rewards/my-wallet'];
    }
    if (this.code === 'calendar') {
      return ['/calendar'];
    }
    if (this.code === 'settings') {
      return ['/settings'];
    }
    if (this.code === 'receipts') {
      return ['/receipt-list'];
    }
    if (this.code === 'sales-receipts') {
      return ['/sales-receipt-list'];
    }
    if (this.code === 'leaderboard') {
      return ['/leaderboard'];
    }
    if (this.code === 'flexiBenefits') {
      this.flexiBenefitLink =
        this.auth$?.organization_settings?.flexi_benefits_url;
      return [this.flexiBenefitLink];
    }
    if (this.code === 'point-history') {
      return ['/point-history'];
    }
    if (this.code === 'announcement') {
      return ['/calendar/announcement'];
    }
    if (this.code === 'event') {
      return ['/calendar/event'];
    }
    return ['/home'];
  }

  label(code) {
    if (code === 'home') {
      return this.translate.instant('common.home');
    }
    if (code === 'nomination') {
      return this.translate.instant('common.nomination');
    }
    if (code === 'feeds') {
      return this.translate.instant(this.feeds.label);
    }
    if (code === 'rewards') {
      return this.translate.instant('common.reward.n');
    }
    if (code === 'my-wallet') {
      return this.translate.instant('common.myWallet');
    }
    if (code === 'calendar') {
      return this.translate.instant('common.calendar');
    }
    if (code === 'settings') {
      return this.translate.instant('common.settings');
    }
    if (code === 'receipts') {
      return this.translate.instant('common.receipts');
    }
    if (code === 'sales-receipts') {
      return this.translate.instant('common.receipts');
    }
    if (code === 'leaderboard') {
      return this.translate.instant('common.leaderboard');
    }
    if (code === 'flexiBenefits') {
      return this.translate.instant('common.flexiBenefits');
    }

    if (code === 'point-history') {
      return this.translate.instant('common.pointsHistory');
    }

    if (code == 'appreciate') {
      if (isAppreciateEnabled(this.auth$) && isNominationEnabled(this.auth$)) {
        return this.translate.instant('common.thanks');
      } else if (isNominationEnabled(this.auth$)) {
        return this.translate.instant('common.nomination');
      } else {
        return this.translate.instant('common.appreciate');
      }
    }

    if (code === 'announcement') {
      return this.translate.instant('common.announcements');
    }
    if (code === 'event') {
      return this.translate.instant('common.events');
    }
    return '';
  }

  async redirectTo(link) {
    this.code = link;
    if (this.link[0].startsWith('https')) {
      if (this.link[0] === this.flexiBenefitLink) {
        this.getDeepLinkToken();
      } else {
        window.open(this.link[0], '_blank');
      }
    } else {
      if (link === 'nomination') {
        this.appreciateStore.dispatch(
          setAppreciate({ mode: FeedType.nomination }),
        );
        await this.router.navigate(['/appreciate/nom-cat']);
      } else {
        await this.router.navigate(this.link);
      }
    }
  }

  getDeepLinkToken() {
    this.api.getDeepLinkToken().then((res) => {
      if (res?.deeplink_token) {
        window.open(
          this.flexiBenefitLink + '/auto-login?token=' + res.deeplink_token,
          '_blank',
        );
      }
    });
  }
}
