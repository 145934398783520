<div class="box__outer">
  <form class="form-group d-flex flex-column gap-3" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="form-field d-flex">
      <input
        [type]="types[0]"
        [class]="lang.isRTL ? 'rtl-input-field input-field' : 'input-field'"
        formControlName="old_password"
        [placeholder]="'authentication.oldPassword' | translate"
      />
      <i
        [ngClass]="types[0] === 'password' ? 'bi-eye-slash-fill' : 'bi-eye-fill'"
        [class]="lang.isRTL ? 'bi rtl-icon-field icon-field' : 'bi icon-field'"
        (click)="switch(0)"
      ></i>
    </div>

    <div class="form-field d-flex">
      <input
        [type]="types[1]"
        [class]="lang.isRTL ? 'rtl-input-field input-field' : 'input-field'"
        formControlName="new_password"
        [placeholder]="'authentication.newPassword' | translate"
      />
      <i
        [ngClass]="types[1] === 'password' ? 'bi-eye-slash-fill' : 'bi-eye-fill'"
        [class]="lang.isRTL ? 'bi rtl-icon-field icon-field' : 'bi icon-field'"
        (click)="switch(1)"
      ></i>
    </div>

    <div class="form-field d-flex">
      <input
        [type]="types[2]"
        [class]="lang.isRTL ? 'rtl-input-field input-field' : 'input-field'"
        formControlName="confirm_new_password"
        [placeholder]="'authentication.confirmNewPassword' | translate"
      />
      <i
        [ngClass]="types[2] === 'password' ? 'bi-eye-slash-fill' : 'bi-eye-fill'"
        [class]="lang.isRTL ? 'bi rtl-icon-field icon-field' : 'bi icon-field'"
        (click)="switch(2)"
      ></i>
    </div>

    <div class="txt--hint d-flex">
      <img src="assets/img/password-hint.png" />
      {{ 'authentication.minimumLengthPassword' | translate : {n: 8} }}
    </div>

    <div class="btn--action d-flex">
      <span class="cancel--btn" (click)="onClose()">
        {{ 'common.cancel' | translate }}
      </span>
      <button
        class="btn-verify"
        type="submit"
        [ngClass]="valid"
        [disabled]="!form.valid"
      >
        <span>{{ 'authentication.changePassword' | translate }}</span>
      </button>
    </div>
  </form>

  <div class="help-block text-danger" *ngIf="errMessage">
    {{ errMessage }}
  </div>
</div>
