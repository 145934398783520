import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { LangService } from 'src/app/services/lang.service';
import { selectAuth } from 'src/app/store/auth/auth.selector';
import { TokenAuthState } from 'src/app/store/auth/auth.state';
import { environment } from 'src/environments/environment';
import {
  getFullName,
  getMyNameAcronym,
  getNameAcronym,
  getProfPic,
  toggleStyleDisplay,
} from '../../utility/common-logic';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  auth$: TokenAuthState;
  imgUrl = environment.url.img;
  style: Partial<CSSStyleDeclaration> = { display: 'none' };
  isBSTLApp = environment.settings.app === 'bstl';
  isPanelOpen: boolean = false;

  constructor(
    private store: Store,
    public lang: LangService,
    private renderer: Renderer2,
    private el: ElementRef,
  ) {}

  ngOnInit() {
    this.store.pipe(select(selectAuth)).subscribe((auth) => {
      this.auth$ = auth;
    });
  }

  toggleStyleDisplay(style: Partial<CSSStyleDeclaration>) {
    this.isPanelOpen = !this.isPanelOpen;
    return toggleStyleDisplay(style);
  }

  get profPic() {
    return getProfPic(this.auth$);
  }

  get acronym() {
    if (this.isBSTLApp) {
      return getNameAcronym(this.auth$?.user_details?.department_name);
    } else {
      return getMyNameAcronym(this.auth$);
    }
  }

  get fullName() {
    return getFullName(this.auth$);
  }

  clickOnProfile(style) {
    // this.isPanelOpen = !this.isPanelOpen;
    const container = document.querySelector('.page-content');
    if (style.display == 'block') {
      this.renderer.addClass(container, 'mat-content-layer');
    } else {
      this.renderer.removeClass(container, 'mat-content-layer');
    }
  }
}
