import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { selectAuth } from 'src/app/store/auth/auth.selector';
import { TokenAuthState } from 'src/app/store/auth/auth.state';
import {
  isAppreciateEnabled,
  isNominationEnabled,
  isPostPollEnabled,
  isRewardEnabled,
} from 'src/app/utility/common-logic';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss'],
})
export class TopMenuComponent implements OnInit {
  auth$: TokenAuthState;
  modules = environment.settings.modules;
  to: string;

  constructor(
    private store: Store,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.store.pipe(select(selectAuth)).subscribe((auth) => {
      this.auth$ = auth;
    });
    this.activatedRoute.url.subscribe((url: UrlSegment[]) => {
      this.to = url.length > 0 ? url[0].path : this.router.url.split('/')[1];
    });
  }

  get isCalendarEnabled() {
    return (
      !!this.auth$.organization_settings?.show_events ||
      !!this.auth$.organization_settings?.show_greetings
    );
  }

  get showAppreciation() {
    return isAppreciateEnabled(this.auth$) || isNominationEnabled(this.auth$);
  }

  get showFeeds() {
    return isAppreciateEnabled(this.auth$) || isPostPollEnabled(this.auth$);
  }

  get showRewards() {
    return isRewardEnabled(this.auth$);
  }

  is(
    to:
      | 'home'
      | 'my-feed'
      | 'rewards'
      | 'my-wallet'
      | 'calendar'
      | 'settings'
      | 'appreciate'
      | 'point-history',
  ) {
    return (
      (to === 'my-wallet' && to === this.router.url.split('/')[2]) ||
      this.to === to
    );
  }

  selectedClass(
    to:
      | 'home'
      | 'my-feed'
      | 'rewards'
      | 'my-wallet'
      | 'calendar'
      | 'settings'
      | 'appreciate'
      | 'point-history',
  ) {
    return this.is(to) ? 'selected' : '';
  }

  fontClass(to: string) {
    return this.to === to ? 'font-selected' : 'font-unselected';
  }
  onClickTopMenu(
    menu:
      | 'home'
      | 'my-feed'
      | 'rewards'
      | 'my-wallet'
      | 'calendar'
      | 'settings'
      | 'appreciate'
      | 'point-history',
  ) {
    sessionStorage.setItem('selected-topMenu', menu);
    this.to = menu;
  }
}
