<div class="search-box-container">
  <i class="bi bi-search" [ngClass]="{ 'rtl--search': lang.isRTL }"></i>
  <input
    type="search"
    [ngModel]="key"
    (ngModelChange)="onKeyChange($event)"
    class="form-control form-control-lg input--search"
    [ngClass]="{ 'rtl--input': lang.isRTL }"
    [placeholder]="placeholder"
  />
  <!-- <i *ngIf="key" class="bi bi-x" [ngClass]="{ 'rtl--x': lang.isRTL }" (click)="clearSearch()"
     [title]="'common.clear' | translate"></i> -->
</div>
