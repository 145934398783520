import { createAction, props } from '@ngrx/store';
import { TokenAuthState } from './auth.state';
import { Cred, ExternalTokenAuth, TokenAuth } from 'src/app/utility/model/token-auth';

// User info
export const getUserInfo = createAction('[Auth] Get User Info');
export const getUserInfoSuccess = createAction(
  '[Auth] Get User Info Success',
  props<{ userInfo: any }>(),
);
export const getUserInfoFailure = createAction(
  '[Auth] Get User Info Failure',
  props<{ error: any }>(),
);

// Organzation settings
export const getOrganizationSettings = createAction(
  '[Auth] Get Organization Settings',
);
export const getOrganizationSettingsSuccess = createAction(
  '[Auth] Get Organization Settings Success',
  props<{ organizationSettings: any }>(),
);
export const getOrganizationSettingsFailure = createAction(
  '[Auth] Get Organization Settings Failure',
  props<{ error: any }>(),
);

// Dashboard data
export const getDashboardData = createAction(
  '[Auth] Get Dashboard Data',
  props<{ topRewardsCategorywise: boolean }>(),
);
export const getDashboradDataSuccess = createAction(
  '[Auth] Get Dashboard Data Success',
  props<{ dashboard: any }>(),
);
export const getDashboardDataFailure = createAction(
  '[Auth] Get Dashboard Data Failure',
  props<{ error: any }>(),
);

// Update data
export const updateAuth = createAction(
  '[Auth] Update Auth',
  props<{ payload: Partial<TokenAuthState> }>(),
);
export const updateAuthSuccess = createAction(
  '[Auth] Update Auth Success'
);
export const updateAuthFailure = createAction(
  '[Auth] Update Auth Failure',
  props<{ error: any }>()
);