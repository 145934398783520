import { GetCartResp } from "src/app/utility/model/cart";
import { FinanceTransactionsPointSummaryResponse } from "src/app/utility/model/finance-transactions-point-summary-response";

export interface LayoutApiState {
  pointsSummary: FinanceTransactionsPointSummaryResponse,
  pointsSummaryLoaded: boolean,
  cartDetails: GetCartResp,
  cartDetailsLoaded: boolean
}

export const initialLayoutApiState: LayoutApiState = {
  pointsSummary: {},
  pointsSummaryLoaded: false,
  cartDetails: {},
  cartDetailsLoaded: false
}