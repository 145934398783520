import { formatDate } from '@angular/common';
import { Component, Input } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { LangService } from 'src/app/services/lang.service';
import { selectAuth } from 'src/app/store/auth/auth.selector';
import { TokenAuthState } from 'src/app/store/auth/auth.state';
import {
  compareDate,
  getFullName,
  getNameAcronym,
  isUserSameAsLoggedInUser,
  ordinalSuffix,
} from 'src/app/utility/common-logic';
import { GreetingType, PeriodType } from 'src/app/utility/enum';
import { PostPollResult } from 'src/app/utility/model/post-poll';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-greetings',
  templateUrl: './greetings.component.html',
  styleUrl: './greetings.component.scss',
})
export class GreetingsComponent {
  @Input() item: PostPollResult | undefined;

  auth$: TokenAuthState;
  GreetingType = GreetingType;
  greeting = environment.settings.greeting;
  img = environment.url.img;

  constructor(
    private store: Store,
    private lang: LangService,
  ) {
    this.store.pipe(select(selectAuth)).subscribe((auth) => {
      this.auth$ = auth;
    });
  }

  get anniversaryDuration() {
    if (this.item?.greeting_info?.type === GreetingType.anniversary) {
      return Number(this.item?.greeting_info?.years_completed);
    }
    return null;
  }

  get key() {
    // for Birthday
    let currentDate = new Date().toJSON().slice(0, 10).replace(/-/g, '-');
    if (this.item?.greeting_info?.type === GreetingType.birthday) {
      if (
        compareDate(this.item?.greeting_info?.date, currentDate) ===
          PeriodType.past &&
        isUserSameAsLoggedInUser(
          this.auth$,
          this.item?.created_by_user_info?.pk,
        )
      ) {
        return 'msg.birthday.past.loggedInUser';
      } else if (
        compareDate(this.item?.greeting_info?.date, currentDate) ===
          PeriodType.past &&
        !isUserSameAsLoggedInUser(
          this.auth$,
          this.item?.created_by_user_info?.pk,
        )
      ) {
        return 'msg.birthday.past.othersLogin';
      } else if (
        compareDate(this.item?.greeting_info?.date, currentDate) ===
          PeriodType.present &&
        isUserSameAsLoggedInUser(
          this.auth$,
          this.item?.created_by_user_info?.pk,
        )
      ) {
        return 'msg.birthday.present.loggedInUser';
      } else if (
        compareDate(this.item?.greeting_info?.date, currentDate) ===
          PeriodType.present &&
        !isUserSameAsLoggedInUser(
          this.auth$,
          this.item?.created_by_user_info?.pk,
        )
      ) {
        return 'msg.birthday.present.othersLogin';
      } else if (
        compareDate(this.item?.greeting_info?.date, currentDate) ===
          PeriodType.future &&
        isUserSameAsLoggedInUser(
          this.auth$,
          this.item?.created_by_user_info?.pk,
        )
      ) {
        return 'msg.birthday.future.loggedInUser';
      } else if (
        compareDate(this.item?.greeting_info?.date, currentDate) ===
          PeriodType.future &&
        !isUserSameAsLoggedInUser(
          this.auth$,
          this.item?.created_by_user_info?.pk,
        )
      ) {
        return 'msg.birthday.future.othersLogin';
      }
    }

    // for anniversary
    if (this.item?.greeting_info?.type === GreetingType.anniversary) {
      if (
        compareDate(this.item?.greeting_info?.date, currentDate) ===
          PeriodType.past &&
        isUserSameAsLoggedInUser(
          this.auth$,
          this.item?.created_by_user_info?.pk,
        )
      ) {
        return 'msg.anniversary.past.loggedInUser';
      } else if (
        compareDate(this.item?.greeting_info?.date, currentDate) ===
          PeriodType.past &&
        !isUserSameAsLoggedInUser(
          this.auth$,
          this.item?.created_by_user_info?.pk,
        )
      ) {
        return 'msg.anniversary.past.othersLogin';
      } else if (
        compareDate(this.item?.greeting_info?.date, currentDate) ===
          PeriodType.present &&
        isUserSameAsLoggedInUser(
          this.auth$,
          this.item?.created_by_user_info?.pk,
        )
      ) {
        return 'msg.anniversary.present.loggedInUser';
      } else if (
        compareDate(this.item?.greeting_info?.date, currentDate) ===
          PeriodType.present &&
        !isUserSameAsLoggedInUser(
          this.auth$,
          this.item?.created_by_user_info?.pk,
        )
      ) {
        return 'msg.anniversary.present.othersLogin';
      } else if (
        compareDate(this.item?.greeting_info?.date, currentDate) ===
          PeriodType.future &&
        isUserSameAsLoggedInUser(
          this.auth$,
          this.item?.created_by_user_info?.pk,
        )
      ) {
        return 'msg.anniversary.future.loggedInUser';
      } else if (
        compareDate(this.item?.greeting_info?.date, currentDate) ===
          PeriodType.future &&
        !isUserSameAsLoggedInUser(
          this.auth$,
          this.item?.created_by_user_info?.pk,
        )
      ) {
        return 'msg.anniversary.future.othersLogin';
      }
    }
    return '';
  }

  get ordinalSuffix() {
    return this.lang.lang === 'en'
      ? ordinalSuffix(this.anniversaryDuration)
      : '';
  }

  getDate() {
    if (!!this.item?.greeting_info?.date) {
      return formatDate(String(this.item?.greeting_info?.date), 'dd', 'en_US');
    }
    return '';
  }

  get fullName() {
    return getFullName(this.auth$, {
      first_name: this.item?.greeting_info?.user_first_name,
      last_name: this.item?.greeting_info?.user_last_name,
      email: this.item?.greeting_info?.user_email,
    });
  }

  get acronym() {
    return getNameAcronym(this.fullName);
  }

  get iconSmall() {
    if (this.item?.greeting_info?.type === GreetingType.anniversary) {
      return 'assets/img/anniversary-icon.svg';
    } else if (this.item?.greeting_info?.type === GreetingType.birthday) {
      return 'assets/img/birthday-icon.svg';
    }
    return '';
  }

  get getFullName() {
    return (
      this.item?.greeting_info?.user_first_name +
      ' ' +
      this.item?.greeting_info?.user_last_name
    )?.trim();
  }
}
