import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LangService } from 'src/app/services/lang.service';
import { Subject, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
})
export class SearchBoxComponent {
  @Input() key = '';
  @Input() placeholder: string;
  @Output() keyChange = new EventEmitter<string>();
  @Output() search = new EventEmitter<string>();
  @Output() clear = new EventEmitter<void>();

  private searchSubject = new Subject<string>();

  constructor(private translate: TranslateService, public lang: LangService) {
    this.placeholder = this.translate.instant('common.search');

    this.searchSubject
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((value) => {
          return of(value);
        })
      )
      .subscribe((value) => {
        this.search.emit(value);
      });
  }

  onKeyChange(value: string): void {
    this.keyChange.emit(value);
    this.searchSubject.next(value);
  }

  clearSearch(): void {
    this.keyChange.emit('');
    this.clear.emit();
  }
}
