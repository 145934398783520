import { createReducer, on } from '@ngrx/store';
import {
  getCartDetails,
  getCartDetailsFailure,
  getCartDetailsSuccess,
  getPointsSummary,
  getPointsSummaryFailure,
  getPointsSummarySuccess,
} from './layout.actions';
import { initialLayoutApiState } from './layout.state';

export const LayoutReducer = createReducer(
  initialLayoutApiState,

  // Points Summary
  on(getPointsSummary, (state) => ({
    ...state,
    pointsSummaryLoaded: false,
    error: null,
  })),
  on(getPointsSummarySuccess, (state, { pointsSummary }) => {
    return {
      ...state,
      pointsSummary,
      pointsSummaryLoaded: true,
      error: null,
    };
  }),
  on(getPointsSummaryFailure, (state, { error }) => ({
    ...state,
    pointsSummaryLoaded: true,
    error,
  })),

  // Cart details
  on(getCartDetails, (state) => ({
    ...state,
    cartDetailsLoaded: false,
    error: null,
  })),
  on(getCartDetailsSuccess, (state, { cartDetails }) => {
    return {
      ...state,
      cartDetails,
      cartDetailsLoaded: true,
      error: null,
    };
  }),
  on(getCartDetailsFailure, (state, { error }) => ({
    ...state,
    cartDetailsLoaded: true,
    error,
  })),
);
