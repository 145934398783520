import { createAction, props } from "@ngrx/store";

// Points Summary
export const getPointsSummary = createAction('[Layout] Get Points Summary');
export const getPointsSummarySuccess = createAction(
  '[Layout] Get Points Summary Success',
  props<{ pointsSummary: any }>()
);
export const getPointsSummaryFailure = createAction(
  '[Layout] Get Points Summary Failure',
  props<{ error: any }>()
);

// Cart details
export const getCartDetails = createAction('[Layout] Get Cart Details');
export const getCartDetailsSuccess = createAction(
  '[Layout] Get Cart Details Success',
  props<{ cartDetails: any }>()
);
export const getCartDetailsFailure = createAction(
  '[Layout] Get Cart Details Failure',
  props<{ error: any }>()
);