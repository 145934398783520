import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { selectAuth } from 'src/app/store/auth/auth.selector';
import { TokenAuthState } from 'src/app/store/auth/auth.state';
import { Cart } from './../utility/model/cart';
import { ApiService } from './api-services/api.service';
import dayjs from 'dayjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  auth$: TokenAuthState;
  spinner = false;
  selectedLan = new BehaviorSubject('');
  cartCount = new BehaviorSubject(0);
  cartDetails = new BehaviorSubject('');
  cartItems = new BehaviorSubject<Cart[]>([]);
  rewardQty = new BehaviorSubject([{ rewardId: null, qty: 1 }]);
  cartStatus = new BehaviorSubject('');
  registerData = new BehaviorSubject([]);
  tabSelect = new BehaviorSubject<any>('');
  filterCount = new BehaviorSubject(0);

  constructor(
    private store: Store,
    private api: ApiService,
  ) {
    this.store.pipe(select(selectAuth)).subscribe((auth) => {
      this.auth$ = auth;
    });
  }

  isMobile() {
    return /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );
  }

  isLogin() {
    if (!!this.auth$?.auth?.token) {
      return dayjs().isSame(this.auth$?.tokenDate, 'day');
    }
    return false;
  }

  setRegisterData(data: any) {
    this.registerData.next(data);
  }

  getRegisterData() {
    return this.cartDetails.asObservable();
  }
}
