import { createReducer, on } from '@ngrx/store';
import { initialTokenAuthState, TokenAuthState } from './auth.state';
import {
  getDashboardData,
  getDashboardDataFailure,
  getDashboradDataSuccess,
  getOrganizationSettings,
  getOrganizationSettingsFailure,
  getOrganizationSettingsSuccess,
  getUserInfo,
  getUserInfoFailure,
  getUserInfoSuccess,
  updateAuth,
  updateAuthFailure,
  updateAuthSuccess,
} from './auth.actions';
import { Organization } from 'src/app/utility/model/organization';

export const AuthReducer = createReducer(
  initialTokenAuthState,

  // User info
  on(getUserInfo, (state) => ({
    ...state,
    userInitialized: false,
    error: null,
  })),
  on(getUserInfoSuccess, (state, { userInfo }) => {
    const auth = { ...userInfo };
    const user_details = { ...userInfo?.user_details };

    const organization_details: Organization = {
      ...state.organization_details,
      ...userInfo?.organization_details,
      pk: user_details.organization_pk,
      name: user_details.organization_name,
    };

    return {
      ...state,
      auth,
      user_details,
      organization_details,
      userInitialized: true,
      error: null,
    };
  }),
  on(getUserInfoFailure, (state, { error }) => ({
    ...state,
    auth: null,
    user_details: undefined,
    organization_details: undefined,
    userInitialized: true,
    error,
  })),

  // Organization Settings
  on(getOrganizationSettings, (state) => ({
    ...state,
    organizationInitialized: false,
    error: null,
  })),
  on(getOrganizationSettingsSuccess, (state, { organizationSettings }) => {
    const organization_details: Organization = {
      ...state.organization_details,
    };

    return {
      ...state,
      organization_details,
      organization_settings: organizationSettings,
      organizationInitialized: true,
      error: null,
    };
  }),
  on(getOrganizationSettingsFailure, (state, { error }) => ({
    ...state,
    organizationInitialized: true,
    error,
  })),

  // Dashboard data
  on(getDashboardData, (state) => ({
    ...state,
    dashboardDataInitialized: false,
    error: null,
  })),
  on(getDashboradDataSuccess, (state, { dashboard }) => {
    return {
      ...state,
      dashboard: dashboard,
      dashboardDataInitialized: true,
      error: null,
    };
  }),
  on(getDashboardDataFailure, (state, { error }) => ({
    ...state,
    dashboardDataInitialized: false,
    error,
  })),

  // Update the values in user_details
  on(updateAuth, (state, { payload }) => {
    console.log("update auth", payload)
    return {
      ...state,
      user_details: payload.user_details
        ? { ...state.user_details, ...payload.user_details }
        : state.user_details,
      organization_details: payload.organization_details
        ? { ...state.organization_details, ...payload.organization_details }
        : state.organization_details,
      organization_settings: payload.organization_settings
        ? { ...state.organization_settings, ...payload.organization_settings }
        : state.organization_settings,
      dashboard: payload.dashboard
        ? { ...state.dashboard, ...payload.dashboard }
        : state.dashboard,
      externalTokenAuth: {
          ...state.externalTokenAuth,
          ...payload.externalTokenAuth,
        },
      tokenDate: payload.auth ? new Date() : state.tokenDate,
      isUpdatingAuth: true,
      ...payload,
    };
  }),
  on(updateAuthSuccess, (state) => ({
    ...state,
    isUpdatingAuth: false,
  })),
  on(updateAuthFailure, (state) => ({
    ...state,
    isUpdatingAuth: false,
  })),
);