import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TokenAuthState } from './auth.state';

export const selectAuthState = createFeatureSelector<TokenAuthState>('auth');

export const selectAuth = createSelector(
  selectAuthState,
  (state: TokenAuthState) => state,
);

export const selectUserDetails = createSelector(
  selectAuthState,
  (state: TokenAuthState) => state.user_details,
);

export const selectUserSuccess = createSelector(
  selectAuthState,
  (state: TokenAuthState) => state?.userInitialized,
);

export const selectOrgDetails = createSelector(
  selectAuthState,
  (state: TokenAuthState) => state.organization_details,
);

export const selectOrgSettings = createSelector(
  selectAuthState,
  (state: TokenAuthState) => state.organization_settings,
);

export const selectOrgSettingsSuccess = createSelector(
  selectAuthState,
  (state: TokenAuthState) => state.organizationInitialized,
);

export const selectDashboardData = createSelector(
  selectAuthState,
  (state: TokenAuthState) => state.dashboard,
);

export const selectDashboardDataSuccess = createSelector(
  selectAuthState,
  (state: TokenAuthState) => state.dashboardDataInitialized,
);

export const selectIsUpdatingAuth = createSelector(
  selectAuthState,
  (state: TokenAuthState) => state.isUpdatingAuth,
);