import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, from, map, mergeMap, of } from 'rxjs';
import { ApiService } from 'src/app/services/api-services/api.service';
import {
  getCartDetails,
  getCartDetailsFailure,
  getCartDetailsSuccess,
  getPointsSummary,
  getPointsSummaryFailure,
  getPointsSummarySuccess,
} from './layout.actions';
import { showError } from 'src/app/utility/common-logic';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class LayoutEffects {
  constructor(
    private apiService: ApiService,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) {}

  private actions$ = inject(Actions);

  getPointsSummary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPointsSummary),
      mergeMap(() =>
        from(this.apiService.getPointsSummary()).pipe(
          map((pointsSummary) => getPointsSummarySuccess({ pointsSummary })),
          catchError((error) => {
            showError(error, this.toastr, this.translate)
            return of(getPointsSummaryFailure({ error }))
          }),
        ),
      ),
    ),
  );

  getCartDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCartDetails),
      mergeMap(() =>
        from(this.apiService.getCartDetails()).pipe(
          map((cartDetails) => getCartDetailsSuccess({ cartDetails })),
          catchError((error) => {
            showError(error, this.toastr, this.translate);
            return of(getCartDetailsFailure({ error }));
          }),
        ),
      ),
    ),
  );
}
