import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api-services/api.service';
import { updateAuth } from 'src/app/store/auth/auth.actions';
import { selectAuth } from 'src/app/store/auth/auth.selector';
import { TokenAuthState } from 'src/app/store/auth/auth.state';
import { clearLocalStorage } from 'src/app/utility/local-storage';
import { environment } from 'src/environments/environment';
import {
  getFullName,
  getMyNameAcronym,
  getNameAcronym,
  getProfPic,
  isAppreciateEnabled,
  isNominationEnabled,
  showError,
} from '../../../utility/common-logic';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
})
export class PanelComponent implements OnInit {
  auth$: TokenAuthState;
  modules = environment.settings.modules;
  panel = environment.settings.panel;
  admin = environment.url.admin;
  isBSTLApp = environment.settings.app === 'bstl';
  myFeeds = environment.settings.myFeeds;
  freshchat = environment.settings.freshchat;
  websiteLink = environment.url.SSOUrl;
  goToSsoLinkOnLogout = environment.settings.goToSsoLinkOnLogout;
  goToAdminDashboard = environment.settings.goToAdminDashboard;

  constructor(
    private store: Store,
    private router: Router,
    private api: ApiService,
    private translate: TranslateService,
    private toastr: ToastrService,
  ) {}

  async ngOnInit() {
    this.store.pipe(select(selectAuth)).subscribe((auth) => {
      this.auth$ = auth;
    });
    if (this.modules.feeds) {
      await this.api
        .getUserFeed('approvals')
        .then((res) => {
          this.store.dispatch(
            updateAuth({
              payload: {
                approvalsCounter: res?.approvals_count,
                showApprovals: res.show_approvals,
              },
            }),
          );
        })
        .catch((err) => showError(err, this.toastr, this.translate));
    }
  }

  get profPic() {
    return getProfPic(this.auth$);
  }

  get acronym() {
    if (this.isBSTLApp) {
      return getNameAcronym(this.auth$?.user_details?.department_name);
    } else {
      return getMyNameAcronym(this.auth$);
    }
  }

  get fullName() {
    return getFullName(this.auth$);
  }

  get isAppreciationOrNominationEnabled() {
    return isAppreciateEnabled(this.auth$) || isNominationEnabled(this.auth$);
  }

  async signOut() {
    if (this.freshchat.isShow && !!(window as any).fcWidget) {
      (window as any).fcWidget.hide();
    }

    await this.api.logOut();

    clearLocalStorage();
    this.api.clearCacheStorage();

    if (this.goToSsoLinkOnLogout && this.websiteLink) {
      window.location.href = this.websiteLink;
    } else {
      this.router.navigate(['/login']);
    }
  }

  get actionClass() {
    let isShow = this.panel.link || this.auth$?.user_details?.is_staff;
    isShow =
      isShow ||
      this.modules.feeds ||
      this.auth$?.organization_settings?.enable_nominations === 1 ||
      this.modules.settings ||
      this.panel.support;
    return isShow ? 'show' : '';
  }

  goToUserFeeds() {
    if (this.router.url.includes('my-feed')) {
      window.location.reload();
    }
    this.router.navigate(['/my-feed']);
  }
}
