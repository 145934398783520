import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  getDashboardData,
  getDashboardDataFailure,
  getDashboradDataSuccess,
  getOrganizationSettings,
  getOrganizationSettingsFailure,
  getOrganizationSettingsSuccess,
  getUserInfo,
  getUserInfoFailure,
  getUserInfoSuccess,
} from './auth.actions';
import { catchError, from, map, mergeMap, of, tap } from 'rxjs';
import { ApiService } from 'src/app/services/api-services/api.service';
import { environment } from 'src/environments/environment';
import { initPrimaryColor } from 'src/app/utility/common-logic';

@Injectable()
export class AuthEffects {
  constructor(private apiService: ApiService) {}

  private actions$ = inject(Actions);

  getUserInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserInfo),
      mergeMap((_) =>
        from(this.apiService.getUserDetails()).pipe(
          map((userInfo) => {
            const applyBgColorFromDjango =
              environment.settings.applyBgColorFromDjango;

            initPrimaryColor(
              userInfo?.organization_details?.background_color,
              applyBgColorFromDjango,
            );

            return getUserInfoSuccess({ userInfo });
          }),
          catchError((error) => of(getUserInfoFailure({ error }))),
        ),
      ),
    ),
  );

  getOrganizationSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getOrganizationSettings),
      mergeMap(() =>
        from(this.apiService.getAllOrganizationSettings()).pipe(
          map((organizationSettings) =>
            getOrganizationSettingsSuccess({ organizationSettings }),
          ),
          catchError((error) => of(getOrganizationSettingsFailure({ error }))),
        ),
      ),
    ),
  );

  getDashboardData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDashboardData),
      mergeMap(({ topRewardsCategorywise }) =>
        from(this.apiService.getDashboardData(topRewardsCategorywise)).pipe(
          map((dashboard) => getDashboradDataSuccess({ dashboard })),
          catchError((error) => of(getDashboardDataFailure({ error }))),
        ),
      ),
    ),
  );
}
