import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LayoutApiState } from './layout.state';

export const selectLayoutApiState =
  createFeatureSelector<LayoutApiState>('layout');

export const selectPointSummary = createSelector(
  selectLayoutApiState,
  (state: LayoutApiState) => state.pointsSummary,
);

export const selectPointSummarySuccess = createSelector(
  selectLayoutApiState,
  (state: LayoutApiState) => state.pointsSummaryLoaded,
);

export const selectCartDetails = createSelector(
  selectLayoutApiState,
  (state: LayoutApiState) => state.cartDetails,
);

export const selectCartDetailsSuccess = createSelector(
  selectLayoutApiState,
  (state: LayoutApiState) => state.cartDetailsLoaded,
);
