import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { fromEvent, merge, of } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { map, take } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api-services/api.service';
import { AxiosService } from 'src/app/services/axios.service';
import { SharedService } from 'src/app/services/shared.service';
import { PasswordChangeModalComponent } from 'src/app/settings/password-change-modal/password-change-modal.component';
import { selectAuth } from 'src/app/store/auth/auth.selector';
import {
  initialTokenAuthState,
  TokenAuthState,
} from 'src/app/store/auth/auth.state';
import { getTokenFromStorage, saveTokenToStorage, showError } from 'src/app/utility/common-logic';
import { environment } from 'src/environments/environment';
import Breadcrumb from '../../utility/model/breadcrumb';
import { ConsentBoxComponent } from '../consent-box/consent-box.component';
import { AuthService } from 'src/app/store/auth/auth.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, OnDestroy {
  auth$: TokenAuthState;
  hasBackdrop = true;
  mode: 'over' | 'push' | 'side' = 'over';
  @Input() hideHeader = '';
  @Input() stickyHeader = '';
  @Input() hideFooter = environment.settings.showFooter === '1' ? '' : '1';
  @Input() stickyFooter = '';
  @ViewChild('leftDrawer') leftDrawer: MatDrawer | undefined;
  @ViewChild('rightDrawer') rightDrawer: MatDrawer | undefined;
  @Input() breadcrumb: Breadcrumb[] = [];
  @Input() layoutBgColor = '';
  @Input() homePage = false;
  @Input() loadApi = true;
  hideLeftDrawer = false;
  hideRightDrawer = false;
  rightDrawerIndex = 0;
  networkStatus = false;
  networkStatus$: Subscription = Subscription.EMPTY;
  applyBgColorFromDjango = environment.settings.applyBgColorFromDjango;
  loaded = false;
  sessionTime: number;
  showBottomMenu = environment.settings.showBottomMenu;
  appName = environment.settings?.appName;
  leftNavigate = environment.settings.leftNavigate;
  showServiceBanner = environment.settings.showServiceBanner;
  showSessionTimer = environment.settings.showSessionTimer;
  showSessionTimerNote = environment.settings.showSessionTimerNote;
  freshchat = environment.settings.freshchat;
  showTimer = environment.settings.showMobileTimer;
  hasAcceptedTermsCondition = Boolean(
    localStorage.getItem('has_accepted_terms_and_cond') ?? false,
  );
  app = environment.settings.app;
  supportChat = environment.settings.supportChat;

  timerStarted = false;
  showMobileTimer = false;
  userSuccess$: any;
  userData: any;
  tokenAuth = initialTokenAuthState.auth;

  constructor(
    private router: Router,
    public translate: TranslateService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private api: ApiService,
    private shared: SharedService,
    private axiosService: AxiosService,
    private store: Store,
    private authService: AuthService,
  ) {
    this.loaded = true;
    this.shared.spinner = true;
  }

  getData(auth: any) {
    saveTokenToStorage(auth?.auth?.token);
    if (
      auth.organization_details?.ask_to_accept_terms_and_conditions >= 2 &&
      !this.hasAcceptedTermsCondition &&
      !auth.user_details?.has_accepted_terms_and_cond
    ) {
      this.openConsentBox();
    }
  }

  get isLoginTnc() {
    return this.router.url === '/login-tnc';
  }

  async ngOnInit() {
    this.store.pipe(select(selectAuth)).subscribe((auth) => {
      this.auth$ = auth;
      this.tokenAuth = auth?.auth;
      if (auth?.auth?.token) {
        this.getData(auth);
        this.loaded = false;
        this.shared.spinner = false;
      }
    });

    if (
      !this.isLoginTnc &&
      (localStorage.length === 0 || !getTokenFromStorage())
    ) {
      await this.router.navigate(['/login']);
    }

    if (this.shared.isMobile() && this.showTimer) {
      this.showMobileTimer = true;
    }

    this.axiosService.configureAxios();
    this.checkNetworkStatus();
    this.checkPassword();
  }

  ngOnDestroy() {
    this.networkStatus$.unsubscribe();
  }

  openSupportChat() {
    window.open(this.supportChat.link, 'blank');
  }

  // To check internet connection stability
  checkNetworkStatus() {
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline'),
    )
      .pipe(map(() => navigator.onLine))
      .subscribe((status) => {
        this.networkStatus = status;
        if (!status) {
          showError(
            'msg.internet.lost',
            this.toastr,
            this.translate,
            true,
            true,
          );
        }
      });
  }

  checkPassword() {
    if (this.auth$?.user_details?.using_default_password) {
      this.dialog.open(PasswordChangeModalComponent, {
        disableClose: true,
        maxWidth: '90vw',
        maxHeight: '90vh',
        data: { isModal: true },
      });
    }
  }

  toggleDrawer(direction: 'left' | 'right') {
    if (direction === 'left') {
      this.hideLeftDrawer = false;
      this.leftDrawer?.toggle();
    }
    if (direction === 'right') {
      this.hideRightDrawer = false;
      this.rightDrawer?.toggle();
    }
  }

  get style(): { [p: string]: any } {
    const style: { [p: string]: any } = {};
    if (!!this.stickyHeader) {
      style['marginTop'] = !this.breadcrumb.length ? '80px' : '140px';
    }
    return style;
  }

  openConsentBox() {
    const dialogRef = this.dialog.open(ConsentBoxComponent, {
      disableClose: true,
      maxWidth: '90vw',
      maxHeight: '90vh',
      data: { isModal: true },
    });
  }
}
