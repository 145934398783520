export function clearLocalStorage() {
  const lang = localStorage.getItem('lang');
  const tips = localStorage.getItem('tips');
  let rememberMe = localStorage.getItem('rememberMe');

  let username = '';
  let password = '';

  if (rememberMe) {
    username = localStorage.getItem('username');
    password = localStorage.getItem('password');
    rememberMe = localStorage.getItem('rememberMe');
  }

  localStorage.clear();

  if (!!lang) {
    localStorage.setItem('lang', lang);
  }
  if (!!tips) {
    localStorage.setItem('tips', tips);
  }
  if (!!username) {
    localStorage.setItem('username', username);
  }

  if (!!password) {
    localStorage.setItem('password', password);
  }

  if (!!rememberMe) {
    localStorage.setItem('rememberMe', rememberMe);
  }
}
