<section class="bottom--menu-section">
  <div class="menu--list">
    <div
      class="d-flex justify-content-around align-items-center flex-row side--menu"
    >
      <div
        *ngFor="let menu of bottomMenus"
        class="d-flex flex-column align-items-center side--menu-item"
        [ngClass]="is(menu) ? 'selected' : ''"
        (click)="redirectTo(menu)"
      >
        <ng-container>
          <span
            [class]="'icon icon--' + menu"
            [ngClass]="selectedClass(menu)"
          ></span>
          <span *ngIf="!is(menu)" [ngClass]="fontClass(menu)">
            {{ label(menu) }}
          </span>
          <span *ngIf="is(menu) && !showActive" [ngClass]="fontClass(menu)">
            {{ label(menu) }}
          </span>
          <div *ngIf="is(menu) && showActive" class="active--dot"></div>
        </ng-container>
      </div>
      <div
        *ngIf="navMenuItems && navMenuItems.length > 5"
        class="d-flex flex-column align-items-center side--menu-item"
        [ngClass]="is('more') ? 'selected' : ''"
        (click)="openModal(content)"
      >
        <span class="icon icon--more" [ngClass]="selectedClass('more')"></span>
        <span *ngIf="!is('more')" [ngClass]="fontClass('more')">
          {{ "common.more._" | translate }}
        </span>
        <div *ngIf="is('more')" class="active--dot"></div>
      </div>
    </div>
  </div>

  <ng-template #content let-modal>
    <div class="modal-body">
      <ul class="list-group">
        <div *ngFor="let menu of moreMenus">
          <li
            (click)="modal.close('Save click'); redirectTo(menu)"
            class="list-group-item"
          >
            <span
              [class]="'d-block text-center icon mx-auto icon--' + menu"
              [ngClass]="selectedClass(menu)"
            ></span>
            <span
              *ngIf="!is(menu)"
              class="d-block text-center"
              [ngClass]="fontClass(menu)"
              >{{ label(menu) }}</span
            >
            <span
              *ngIf="is(menu) && !showActive"
              class="d-block text-center"
              [ngClass]="fontClass(menu)"
              >{{ label(menu) }}</span
            >
            <div *ngIf="is(menu)" class="active--dot"></div>
          </li>
        </div>

        <li class="list-group-item">
          <span
            (click)="modal.close('Save click')"
            class="d-block text-center icon mx-auto icon icon--close"
          ></span>
        </li>
      </ul>
    </div>
  </ng-template>
</section>
