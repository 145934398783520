import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/api-services/api.service';
import { LangService } from 'src/app/services/lang.service';
import { selectUserDetails } from 'src/app/store/auth/auth.selector';
import { AmplifyMsgTone, AmplifyTypes } from 'src/app/utility/enum';
import { UserProfile } from 'src/app/utility/model/user-profile';
import { environment } from 'src/environments/environment';

interface DialogData {
  single: boolean;
  desc?: string;
  title?: string;
  amplifyType: number;
  coreValue: any;
}

@Component({
  selector: 'app-amplify-modal',
  templateUrl: './amplify-modal.component.html',
  styleUrls: ['./amplify-modal.component.scss'],
})
export class AmplifyModalComponent implements OnInit {
  userDetails: UserProfile;
  languageLabel = environment.settings.languageLabel;
  lang: string;
  aiMessage: any;
  loading = false;
  desc = '';
  title = '';
  companyCoreValue = '';
  formattedAiMsg = '';
  formattedAiTitle = '';
  btnSelected = 'expressive';
  msgTone = '';
  stepsToAmplify = [];
  pollOptions = [];
  amplifyMsgTones = AmplifyMsgTone;
  amplifyTypes = AmplifyTypes;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private api: ApiService,
    private dialogRef: MatDialogRef<AmplifyModalComponent>,
    private translate: TranslateService,
    public langService: LangService,
    private store: Store,
  ) {}

  async ngOnInit() {
    this.store.pipe(select(selectUserDetails)).subscribe((user) => {
      this.userDetails = user;
    });
    this.lang =
      this.languageLabel[
        localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en'
      ];
    if (this.data?.coreValue) {
      this.companyCoreValue = this.data?.coreValue?.name;
    }
    if (this.data?.desc) {
      this.desc = this.data?.desc;
    }
    if (this.data?.title) {
      this.title = this.data?.title;
    }
    if (this.data.amplifyType === this.amplifyTypes.appreciate) {
      let step1 = this.translate.instant('common.amplify.appreciate.step1');
      let step2 = this.translate.instant('common.amplify.appreciate.step2');
      let step3 = this.translate.instant('common.amplify.appreciate.step3');
      this.stepsToAmplify.push(step1, step2, step3);
      if (this.desc && this.companyCoreValue) {
        this.getAmplifiedAppreciation();
      }
    } else if (this.data.amplifyType === this.amplifyTypes.post) {
      let step1 = this.translate.instant('common.amplify.post.step1');
      this.stepsToAmplify.push(step1);
      if (this.desc) {
        this.getAmplifiedPost();
      }
    } else if (this.data.amplifyType === this.amplifyTypes.poll) {
      let step1 = this.translate.instant('common.amplify.poll.step1');
      this.stepsToAmplify.push(step1);
      if (this.title) {
        this.getAmplifiedPoll();
      }
    }
    let lastStep = this.translate.instant('common.amplify.lastStep');
    this.stepsToAmplify.push(lastStep);
  }

  get exampleTxt() {
    if (this.data.amplifyType === this.amplifyTypes.appreciate) {
      return this.translate.instant('common.amplify.appreciate.exampleTxt');
    } else if (this.data.amplifyType === this.amplifyTypes.post) {
      return this.translate.instant('common.amplify.post.exampleTxt');
    } else if (this.data.amplifyType === this.amplifyTypes.poll) {
      return this.translate.instant('common.amplify.poll.exampleTxt');
    } else {
      return '';
    }
  }

  async getAmplifiedAppreciation() {
    this.loading = true;
    let params = {
      userInputText: this.desc,
      language: this.lang,
    };
    if (this.companyCoreValue !== '') {
      params['companyCoreValue'] = this.companyCoreValue;
    }
    await this.api.getAmplifyTxt(params).then((res) => {
      if (res) {
        this.aiMessage = res?.ai_message;
        this.formattedAiMsg = this.aiMessage;
        this.loading = false;
        this.btnSelected = 'expressive';
      }
    });
  }

  async getAmplifiedPost() {
    this.loading = true;
    let params = {
      userInputText: this.title,
      userInputText2: this.desc,
      language: this.lang,
      formatResponse: 'json',
      uniqueUserId: String(this.userDetails?.pk),
    };
    await this.api.getAmplifyPost(params).then((res) => {
      if (res) {
        this.aiMessage = res?.ai_message;
        this.formattedAiMsg = this.aiMessage?.content;
        this.formattedAiTitle = this.aiMessage?.title;
        this.loading = false;
        this.btnSelected = 'expressive';
      }
    });
  }

  async getAmplifiedPoll() {
    this.loading = true;
    let params = {
      userInputText: this.title,
      language: this.lang,
      formatResponse: 'json',
      uniqueUserId: String(this.userDetails?.pk),
    };
    await this.api.getAmplifyPoll(params).then((res) => {
      if (res) {
        this.aiMessage = res?.ai_message;
        this.formattedAiTitle = this.aiMessage.title;
        Object.keys(this.aiMessage).forEach((key) => {
          if (key.startsWith('Option')) {
            this.pollOptions.push(this.aiMessage[key]);
          }
        });
        this.loading = false;
        this.btnSelected = 'expressive';
      }
    });
  }

  btnClass(btnId: string) {
    return this.btnSelected === btnId ? 'blue--btn' : '';
  }

  msgSelected(btnId: string) {
    this.btnSelected = btnId;
    this.msgTone = this.amplifyMsgTones[this.btnSelected];
    this.regenerateAmplifyTxt();
  }

  async regenerateAmplifyTxt() {
    this.loading = true;
    if (!this.msgTone) {
      this.msgTone = this.amplifyMsgTones[this.btnSelected];
    }
    let params = {};
    let input = {
      messageTone: this.msgTone,
      language: this.lang,
      uniqueUserId: String(this.userDetails?.pk),
    };
    if (this.data.amplifyType === this.amplifyTypes.appreciate) {
      params = {
        messageTone: this.msgTone,
        textToEdit: this.aiMessage,
        language: this.lang,
      };
      if (this.companyCoreValue !== '') {
        params['companyCoreValue'] = this.companyCoreValue;
      }
    } else if (this.data.amplifyType === this.amplifyTypes.post) {
      params = {
        content_type: 'post',
        inputs: [
          {
            ...input,
            textToEdit: this.aiMessage?.title,
          },
          {
            ...input,
            textToEdit: this.aiMessage?.content,
          },
        ],
      };
    } else if (this.data.amplifyType === this.amplifyTypes.poll) {
      params = {
        content_type: 'poll',
        inputs: [
          {
            ...input,
            textToEdit: this.aiMessage.title,
          },
        ],
      };
      Object.keys(this.aiMessage).forEach((key) => {
        if (key.startsWith('Option')) {
          params['inputs'].push({
            ...input,
            textToEdit: this.aiMessage[key],
          });
        }
      });
    }
    await this.api.regenerateAmplifyTxt(params).then((res) => {
      if (res) {
        let result = res?.ai_message;
        if (this.data.amplifyType === this.amplifyTypes.appreciate) {
          this.formattedAiMsg = result;
        } else if (this.data.amplifyType === this.amplifyTypes.post) {
          this.formattedAiMsg = result?.content;
          this.formattedAiTitle = result?.title;
        } else if (this.data.amplifyType === this.amplifyTypes.poll) {
          this.pollOptions = [];
          this.formattedAiTitle = result?.title;
          Object.keys(result).forEach((key) => {
            if (key.startsWith('Option')) {
              this.pollOptions.push(result[key]);
            }
          });
        }
        this.loading = false;
      }
    });
  }

  useAmplifiedTxt() {
    let desc = this.formattedAiMsg;
    let title = this.formattedAiTitle;
    let pollOptions = this.pollOptions;
    this.dialogRef.close({ desc, title, pollOptions });
  }
}
