import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { updateAuth } from 'src/app/store/auth/auth.actions';
import { selectAuth } from 'src/app/store/auth/auth.selector';
import { TokenAuthState } from 'src/app/store/auth/auth.state';
import { showError, toggleStyleDisplay } from 'src/app/utility/common-logic';
import { environment } from 'src/environments/environment';
import { ApiService } from '../../services/api-services/api.service';
import { NotificationList } from '../../utility/model/notification-list';

@Component({
  selector: 'app-notif',
  templateUrl: './notif.component.html',
  styleUrls: ['./notif.component.scss'],
})
export class NotifComponent implements OnInit {
  auth$: TokenAuthState;
  @Input() mode: 'page' | 'dropdown' = 'page';
  notif?: NotificationList;
  toggleStyleDisplay = toggleStyleDisplay;
  style: Partial<CSSStyleDeclaration> = { display: 'none' };
  header = environment.settings.header;

  constructor(
    private store: Store,
    private api: ApiService,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) {}

  async ngOnInit() {
    this.store.pipe(select(selectAuth)).subscribe((auth) => {
      this.auth$ = auth;
    });
    await this.getNotifications();
  }

  async getNotifications() {
    await this.api
      .getNotifications()
      .then((res) => {
        this.notif = res;
        this.store.dispatch(
          updateAuth({
            payload: { notifBadgeCounter: res?.badge_counter },
          }),
        );
      })
      .catch((err) => showError(err, this.toastr, this.translate));
  }

  get notifIcon() {
    if (this.header.blackIconForNotification) {
      return 'assets/img/icon_notification_black.png';
    } else {
      return 'assets/img/icon_notification.png';
    }
  }
}
