import { DashboardData } from 'src/app/utility/model/dashboard-data';
import {
  Organization,
  OrganizationSettings,
} from 'src/app/utility/model/organization';
import {
  Cred,
  ExternalTokenAuth,
  TokenAuth,
} from 'src/app/utility/model/token-auth';
import { UserProfile } from 'src/app/utility/model/user-profile';

export interface TokenAuthState {
  auth?: TokenAuth | undefined | null;
  authInitialized?: boolean;
  user_details: UserProfile | undefined;
  userInitialized: boolean;
  organization_details: Organization | undefined;
  organization_settings: OrganizationSettings | undefined;
  organizationInitialized: boolean;
  dashboard: DashboardData | undefined;
  dashboardDataInitialized: boolean;
  externalTokenAuth: ExternalTokenAuth | null;
  timestamp: string | null;
  externalLogin: boolean | null;
  token: string | null;
  tokenDate: Date | null;
  appName: string | null;
  subDomain: string | null;
  uuid: string | null;
  cred: Cred | null;
  notifBadgeCounter: number | null;
  approvalsCounter: number | null;
  showApprovals: boolean | null;
  isUpdatingAuth: boolean | null;
}

export const initialTokenAuthState: TokenAuthState = {
  auth: undefined,
  authInitialized: false,
  user_details: {},
  userInitialized: false,
  organization_details: {},
  organization_settings: {},
  organizationInitialized: false,
  dashboard: {},
  dashboardDataInitialized: false,
  externalTokenAuth: {},
  timestamp: null,
  externalLogin: false,
  token: null,
  tokenDate: null,
  appName: null,
  subDomain: null,
  uuid: null,
  cred: {},
  notifBadgeCounter: null,
  approvalsCounter: null,
  showApprovals: false,
  isUpdatingAuth: false,
};
