import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api-services/api.service';
import { selectUserDetails } from 'src/app/store/auth/auth.selector';
import { showError } from 'src/app/utility/common-logic';
import { UserProfile } from 'src/app/utility/model/user-profile';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-consent-box',
  templateUrl: './consent-box.component.html',
  styleUrls: ['./consent-box.component.scss'],
})
export class ConsentBoxComponent implements OnInit {
  loading: boolean = false;
  userDetails: UserProfile;
  isFullContent = environment.settings?.afterLoginConsentBox?.isFullContent;
  content = environment.settings?.afterLoginConsentBox?.content;

  constructor(
    private api: ApiService,
    private dialogRef: MatDialogRef<ConsentBoxComponent>,
    private translate: TranslateService,
    private toastr: ToastrService,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.store.pipe(select(selectUserDetails)).subscribe((user) => {
      this.userDetails = user;
    });
  }

  closeModal() {
    localStorage.setItem('has_accepted_terms_and_cond', String(true));
    this.dialogRef.close({});
  }
  async onAgree() {
    const body = {
      has_accepted_terms_and_cond: true,
    };
    const userId = this.userDetails?.pk;
    this.loading = true;
    await this.api
      .editUser(userId, body)
      .then((res) => {
        this.closeModal();
      })
      .catch((err) => showError(err, this.toastr, this.translate))
      .finally(() => (this.loading = false));
  }
}
