import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api-services/api.service';
import { selectOrgSettings } from 'src/app/store/auth/auth.selector';
import { ImgFile } from 'src/app/utility/model/images';
import { environment } from 'src/environments/environment';

interface DialogData {
  dialogMode: boolean;
  image: ImgFile;
}

@Component({
  selector: 'app-process-receipt-image',
  templateUrl: './process-receipt-image.component.html',
  styleUrls: ['./process-receipt-image.component.scss'],
})
export class ProcessReceiptImageComponent implements OnInit {
  @Output() processImage: EventEmitter<any> = new EventEmitter<any>();
  loading: boolean = false;
  draftData: any;
  @ViewChild('receiptImage') receiptImage: ElementRef;
  imageWidth: number;
  imageHeight: number;
  receiptNumber: string;
  dateOfReceipt: Date;
  maxDate: string;
  description: string;
  amount: string;
  orderNum: string;
  cashier: string;
  otherOcrData: any = [];
  showReceiptDetails: boolean = false;
  receiptData: any = {};
  isSalesReceipt = false;
  isUploadSuccessful = false;
  showWaitScreen = false;
  processingText = '';
  draftId = '';
  appName = environment.settings.app;
  enableHeuristics = environment.settings.enableHeuristics;
  heuristicData: any = [];
  lineItems: any = [];
  retries: number = 3;
  attempts: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private api: ApiService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<ProcessReceiptImageComponent>,
    private store: Store,
  ) {}

  async ngOnInit() {
    this.store.pipe(select(selectOrgSettings)).subscribe((settings) => {
      if (settings?.enable_receipts_upload === 1) {
        this.isSalesReceipt = true;
      }
    });

    this.getMaxDate();
    await this.saveDraft();
  }

  getMaxDate() {
    const today = new Date();
    this.maxDate = today.toISOString().split('T')[0];
  }

  dateOfReceiptValidationGuard() {
    if (
      !this.dateOfReceipt ||
      dayjs(this.dateOfReceipt).isAfter(this.maxDate)
    ) {
      this.dateOfReceipt = new Date();
      setTimeout(() => (this.dateOfReceipt = null), 1);
    }
  }

  getImageDimensions() {
    this.imageWidth = this.receiptImage.nativeElement.clientWidth;
    this.imageHeight = this.receiptImage.nativeElement.clientHeight;
  }

  getOverlayStyles(ocrObj: any, completeOcr: boolean): any {
    this.getImageDimensions();
    let area: { Left: number; Top: number; Width: number; Height: number };
    if (completeOcr) {
      area = Object.values(ocrObj)[0]['geometry'];
    } else {
      area = ocrObj;
    }
    const left = area.Left * this.imageWidth;
    const top = area.Top * this.imageHeight;
    const width = area.Width * this.imageWidth;
    const height = area.Height * this.imageHeight;

    return {
      left: left + 'px',
      top: top + 'px',
      width: width + 'px',
      height: height + 'px',
    };
  }

  async saveDraft() {
    this.loading = true;
    this.processingText = this.translate.instant(
      'common.receipt.uploadingImage',
    );
    const fd = new FormData();
    if (this.data.image) {
      const file = this.data.image?.imgFile;
      fd.append('upload', file, file.name);
    }
    if (this.enableHeuristics) {
      fd.append('heuristic', this.appName);
    }
    await this.api
      .saveReceiptDraft(fd)
      .then((res: any) => {
        if (res) {
          this.draftId = res?.draft_id;
          this.isUploadSuccessful = true;
          this.processingText = this.translate.instant(
            'common.receipt.processingImage',
          );
          setTimeout(() => {
            if (this.draftId) {
              this.getDraftDetails();
            }
          }, 6000);
        }
      })
      .catch(() => {
        this.isUploadSuccessful = false;
      });
  }

  getDraftDetails() {
    this.loading = true;
    this.api
      .getReceiptDraft(this.draftId)
      .then((res) => {
        this.draftData = res[0];
        if (this.draftData.status !== 'OCR Completed') {
          this.showWaitScreen = true;
          this.processingText = this.translate.instant(
            'common.receipt.waitingForOcr',
          );
          setTimeout(() => {
            if (this.attempts < this.retries) {
              this.getDraftDetails();
              this.attempts++;
            }
            if (this.attempts === this.retries) {
              this.goToReceiptForm();
            }
          }, 2000);
        } else {
          this.showWaitScreen = false;
          if (Object.keys(this.draftData?.ocr_data_json).length > 0) {
            let ocr_data_json = this.draftData.ocr_data_json;
            if (
              ocr_data_json?.receipt_numbers &&
              ocr_data_json?.receipt_numbers.length > 0
            ) {
              this.receiptNumber = Object.values(
                ocr_data_json.receipt_numbers[0],
              )[0]['value'];
            }
            if (ocr_data_json?.amounts && ocr_data_json?.amounts.length > 0) {
              this.amount = Object.values(ocr_data_json.amounts[0])[0][
                'value'
              ].normalized_amount['amount'];
            }
            if (ocr_data_json?.dates && ocr_data_json?.dates.length > 0) {
              const date = Object.values(ocr_data_json.dates[0])[0]['value']
                .formatted_date;
              this.dateOfReceipt = date.toLocaleString();
            }
            if (ocr_data_json?.others && ocr_data_json?.others?.length > 0) {
              this.draftData.ocr_data_json.others.forEach((item: any) => {
                Object.values(item).forEach((obj: any) => {
                  if (obj.geometry) {
                    this.otherOcrData.push(obj);
                  }
                });
              });
              this.description = this.otherOcrData
                .map((item: any) => item.value)
                .join('\n');
            }
            if (this.enableHeuristics) {
              if (
                ocr_data_json?.Heuristic_Bound &&
                ocr_data_json?.Heuristic_Bound?.length > 0
              ) {
                this.draftData.ocr_data_json.Heuristic_Bound.forEach(
                  (item: any) => {
                    if (item.orderNum) {
                      this.orderNum = item.orderNum.value;
                      this.heuristicData.push(item.orderNum);
                    } else if (item.cashier) {
                      this.cashier = item.cashier.value;
                      this.heuristicData.push(item.cashier);
                    }
                  },
                );
              }
              if (
                ocr_data_json?.line_items &&
                ocr_data_json?.line_items.length > 0
              ) {
                ocr_data_json?.line_items.forEach((item: any) => {
                  const lineItem = {
                    item: item.ITEM.value,
                    quantity: item.QUANTITY.value,
                  };
                  this.lineItems.push(lineItem);
                });
              }
            }

            if (
              this.receiptNumber ||
              this.amount ||
              this.dateOfReceipt ||
              this.description
            ) {
              this.showReceiptDetails = true;
              this.saveReceiptData();
            } else {
              this.goToReceiptForm();
            }
          } else {
            this.goToReceiptForm();
          }
        }
      })
      .catch(() => this.goToReceiptForm())
      .finally(() => (this.loading = false));
  }

  goToReceiptForm() {
    this.saveReceiptData();
    let image: ImgFile;
    if (this.data && this.data?.image) {
      image = this.data?.image;
    }
    this.dialogRef.close({ image });
  }

  saveReceiptData() {
    if (this.receiptNumber) {
      this.receiptData['receiptNumber'] = this.receiptNumber;
    }
    if (this.dateOfReceipt) {
      this.receiptData['dateOfReceipt'] = this.dateOfReceipt;
    }
    if (this.description) {
      this.receiptData['description'] = this.description;
    }
    if (this.amount) {
      this.receiptData['amount'] = this.amount;
    }
    if (this.orderNum) {
      this.receiptData['orderNum'] = this.orderNum;
    }
    if (this.cashier) {
      this.receiptData['cashier'] = this.cashier;
    }
    if (this.lineItems) {
      this.receiptData['lineItems'] = this.lineItems;
    }
    if (this.data && this.data?.image) {
      this.receiptData['image'] = this.data.image;
    }
  }
}
